<template>
  <land-section
    id="section-issue-center"
    :space="space"
    color="grey lighten-4"
  >
    <v-container
      style="max-width: 1200px; padding: 0 16px;"
    >
      <v-row
        no-gutters
      >
        <v-col
          cols="12"
          md="9"
          sm="12"
        >
          <issue-center-edit
            @issue-success="onIssueSuccess"
          />
          <issue-center-contents
            ref="wdIssueContents"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="hidden-sm-and-down"
        >
          <issue-center-user ref="userCard" />

          <div class="co-w-full co-h16" />
          <div class="co-flex-col rounded white">
            <div class="co-flex-col px-5 mt-4">
              <land-title
                title="热门活动"
                space="1"
              />
              <land-divider
                color="primary"
                width="28"
              />
            </div>
            <issue-center-activity />
          </div>

          <div class="co-w-full co-h16" />
          <div class="co-flex-col rounded white">
            <div class="co-flex-col px-5 mt-4">
              <land-title
                title="热门文章"
                space="1"
              />
              <land-divider
                color="primary"
                width="28"
              />
            </div>
            <issue-center-article />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </land-section>
</template>
<script>
  export default {
    name: 'SectionIssueCenter',
    components: {
      IssueCenterActivity: () => import('@/pages/sections/home/IssueCenterActivity.vue'),
      IssueCenterArticle: () => import('@/pages/sections/home/IssueCenterArticle.vue'),
      IssueCenterContents: () => import('@/pages/sections/home/IssueCenterContents.vue'),
      IssueCenterEdit: () => import('@/pages/sections/home/IssueCenterEdit.vue'),
      IssueCenterUser: () => import('@/pages/sections/home/IssueCenterUser.vue')
    },
    data () {
      return {
        space: 40
      }
    },
    methods: {
      resetCover (ev) {
        const frm = this.$refs['userCard']
        if (frm) {
          frm.resetCover(ev)
        }
      },
      onIssueSuccess (item) {
        const wd = this.$refs.wdIssueContents
        if (wd) {
          wd.add(item)
        }
      },
      onChangeContent (ev) {
        console.log({ ev })
      }
    }
  }
</script>
